import { template as template_a947e62f1c0e4bd5b74f7c2b66c574c2 } from "@ember/template-compiler";
const WelcomeHeader = template_a947e62f1c0e4bd5b74f7c2b66c574c2(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
