import { template as template_52dd59d732244bbaa28a49ea4e02dc5e } from "@ember/template-compiler";
const FKControlMenuContainer = template_52dd59d732244bbaa28a49ea4e02dc5e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
