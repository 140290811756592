import { template as template_875c7049dcc04cc99e39b090cc1d6852 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_875c7049dcc04cc99e39b090cc1d6852(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
